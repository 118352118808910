<template>
  <v-container class="w-75 mt-10">
    <v-btn tile color="success" @click="$router.back()">Back</v-btn>

    <v-stepper v-model="e6" vertical>
      <v-stepper-step @click="e6 = 1" :complete="e6 > 1" step="1">
        Editar cuenta de administrador
        <!-- <small>Summarize if needed</small> -->
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card color="grey lighten-5" class="mb-12">
          <v-card-text class="text--primary">
            <div class="form-wrapper">
              <v-text-field
                type="text"
                v-model="formData.admin_nombre"
                label="Nombre"
                outlined
                clearable
              />

              <v-text-field
                type="text"
                v-model="formData.admin_email"
                label="Email"
                outlined
              />

              <v-text-field
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show1 = !show1"
                :type="show1 ? 'text' : 'password'"
                v-model="formData.admin_password"
                label="Password"
                outlined
              />
            </div>
          </v-card-text>
        </v-card>
        <v-btn color="primary" @click="e6 = 2"> Continue </v-btn>
        <v-btn text class="ml-2"> Cancel </v-btn>
      </v-stepper-content>

      <v-stepper-step @click="e6 = 2" :complete="e6 > 2" step="2">
        Informacion de la iglesia
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-card color="grey lighten-5" class="mb-12">
          <v-card-text>
            <div class="form-wrapper">
              <div class="info-iglesia">
                <!-- <label for="Icon" class="d-flex justify-center poss_relative">
                  <img
                    id="iglesia_logo"
                    :src="formData.iglesia_logo"
                    alt=""
                    srcset=""
                  />
                  <p class="poss_absolute" style="bottom: -10px">
                    Adjunte Logo (transparente)
                  </p>
                  <input
                    type="file"
                    name=""
                    id="Icon"
                    style="display: none"
                    accept="image/*"
                    @change="(e) => preview(e, 'iglesia_logo')"
                  />
                </label> -->

                <div>
                  <v-row>
                    <v-col>
                      <div class="flex justify-center">
                        <label for="iglesia_logo" class="">
                          <img
                            id=""
                            :src="formData.iglesia_logo"
                            alt=""
                            srcset=""
                          />

                          <input
                            type="file"
                            name=""
                            id="iglesia_logo"
                            style="display: none"
                            accept="image/*"
                            @change="(e) => preview(e, 'iglesia_logo')"
                          />
                        </label>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <p class="mt-n10 text-center">
                        Adjunte Logo(Fondo tranparente)
                      </p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <div class="flex justify-center">
                        <label for="SLIDE_1_ICON" class="">
                          <img
                            id=""
                            :src="formData.SLIDE_1_ICON"
                            alt=""
                            srcset=""
                          />

                          <input
                            type="file"
                            name=""
                            id="SLIDE_1_ICON"
                            style="display: none"
                            accept="image/*"
                            @change="(e) => preview(e, 'SLIDE_1_ICON')"
                          />
                        </label>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <p class="mt-n10 text-center">
                        Foto de Inicio (1920x950) <br />
                        (Recomendable No Letras)
                      </p>
                    </v-col>
                  </v-row>
                </div>
                <div class="flex-grow-1 info-content">
                  <v-text-field
                    v-for="(f, index) in iglesiaInfo"
                    :key="index"
                    :name="f.label"
                    :type="f.type"
                    v-model="formData[f.model]"
                    :label="f.label"
                    outlined
                  />
                  <v-checkbox
                    label="Recibe Pagos/Donaciones"
                    v-model="formData.Donaciones"
                  />
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-btn color="primary" @click="e6 = 3"> Continue </v-btn>
        <v-btn text class="ml-2" @click="e6 = 1"> Cancel </v-btn>
      </v-stepper-content>

      <v-stepper-step @click="e6 = 3" :complete="e6 > 3" step="3">
        Slides de la pagina
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-container>
          <v-row class="my-5">
            <v-col cols="12" sm="6" md="3" v-for="(item, i) in slides" :key="i">
              <v-card flat class="hover-card" style="height: 360px">
                <div class="d-flex justify-center">
                  <label :for="item.ICON" class="">
                    <img
                      :src="formData[item.ICON]"
                      alt=""
                      srcset=""
                      style="width: 200px"
                    />
                    <input
                      type="file"
                      name=""
                      :id="item.ICON"
                      style="display: none"
                      accept="image/*"
                      @change="(e) => preview(e, item.ICON)"
                    />
                  </label>
                </div>
                <p class="text-center mt-1 mb-5">1920 x 950</p>
                <v-card-title class="d-flex justify-center mt-n5" primary-title>
                  <v-text-field
                    outlined
                    type="text"
                    v-model="formData[item.TITULO]"
                  />
                  <v-text-field
                    outlined
                    type="text"
                    v-model="formData[item.SUBTITULO]"
                  />
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-btn color="primary" @click="e6 = 4"> Continue </v-btn>
        <v-btn text class="ml-2" @click="e6 = 2"> Cancel </v-btn>
      </v-stepper-content>

      <v-stepper-step @click="e6 = 4" :complete="e6 > 4" step="4">
        Area de peticiones
      </v-stepper-step>

      <v-stepper-content step="4">
        <v-container>
          <v-row class="my-5">
            <v-col
              cols="12"
              sm="6"
              md="3"
              v-for="(item, i) in peticion"
              :key="i"
            >
              <v-card flat class="hover-card" style="height: 560px">
                <div class="d-flex justify-center">
                  <label :for="item.ICON" class="">
                    <v-img
                      :src="formData[item.ICON]"
                      alt=""
                      srcset=""
                      style="width: 200px"
                    />
                    <input
                      type="file"
                      name=""
                      :id="item.ICON"
                      style="display: none"
                      accept="image/*"
                      @change="(e) => preview(e, item.ICON)"
                    />
                  </label>
                </div>
                <p class="text-center mt-1 mb-5">800 x 950</p>
                <v-card-title class="d-flex justify-center mt-n5" primary-title>
                  <v-text-field
                    type="text"
                    outlined
                    v-model="formData[item.TITULO]"
                  />
                  <v-text-field
                    v-if="item.SUBTITULO"
                    type="text"
                    outlined
                    v-model="formData[item.SUBTITULO]"
                  />
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-btn color="primary" @click="e6 = 5"> Continue </v-btn>
        <v-btn text class="ml-2" @click="e6 = 3"> Cancel </v-btn>
      </v-stepper-content>

      <v-stepper-step @click="e6 = 5" :complete="e6 > 5" step="5">
        Area de Video
      </v-stepper-step>

      <v-stepper-content step="5">
        <v-container>
          <v-row class="my-5">
            <v-col cols="12" sm="6" md="3">
              <v-card flat>
                <input
                  type="file"
                  name=""
                  id="Video"
                  style=""
                  accept="video/*"
                  @change="(e) => preview(e, 'Video')"
                />
                <video
                  v-if="formData.Video != ''"
                  height="200px"
                  :src="formData.Video"
                  autoplay
                  controls
                ></video>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-btn color="primary" @click="e6 = 6"> Continue </v-btn>
        <v-btn text class="ml-2" @click="e6 = 4"> Cancel </v-btn>
      </v-stepper-content>

      <v-stepper-step @click="e6 = 6" :complete="e6 > 6" step="6">
        Colores
      </v-stepper-step>

      <v-stepper-content step="6">
        <v-container>
          <v-row class="my-5">
            <v-col cols="12" sm="6" md="4">
              Color Primario
              <v-color-picker
                v-model="formData.PrimaryColor"
                elevation="2"
              ></v-color-picker>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              Color Secundario
              <v-color-picker
                mode="rgba"
                v-model="formData.SecondaryColor"
                elevation="2"
              ></v-color-picker>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              Color del Footer
              <v-color-picker
                mode="rgba"
                v-model="formData.FooterColor"
                elevation="2"
              ></v-color-picker>
            </v-col>
          </v-row>
        </v-container>

        <v-btn color="primary" @click="e6 = 7"> Finish </v-btn>
        <v-btn text class="ml-2" @click="e6 = 5"> Cancel </v-btn>
      </v-stepper-content>

      <v-stepper-step @click="e6 = 7" :complete="e6 > 7" step="7">
        Donaciones
      </v-stepper-step>

      <v-stepper-content step="7">
        <v-container>
          <v-row class="my-5">
            <v-col
              cols="12"
              sm="6"
              md="3"
              v-for="(item, i) in donacion"
              :key="i"
            >
              <v-card flat class="hover-card" style="height: 560px">
                <div class="d-flex justify-center">
                  <label :for="item.ICON" class="">
                    <v-img
                      :src="formData[item.ICON]"
                      alt=""
                      srcset=""
                      style="width: 200px"
                    />
                    <input
                      type="file"
                      name=""
                      :id="item.ICON"
                      style="display: none"
                      accept="image/*"
                      @change="(e) => preview(e, item.ICON)"
                    />
                  </label>
                </div>
                <p class="text-center mt-1 mb-5">800 x 950</p>
                <v-card-title class="d-flex justify-center mt-n5" primary-title>
                  <v-text-field
                    type="text"
                    outlined
                    v-model="formData[item.TITULO]"
                  />
                  <v-text-field
                    v-if="item.SUBTITULO"
                    type="text"
                    outlined
                    v-model="formData[item.SUBTITULO]"
                  />
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-btn
          color="primary"
          @click="
            $store.dispatch('update_iglesia', formData).then((res) => {
              OpenWeb();
            })
          "
        >
          Finish
        </v-btn>
        <v-btn text class="ml-2" @click="e6 = 3"> Cancel </v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import AboutThree from "../components/about/AboutThree.vue";
import PortfolioThree from "../components/portfolio/PortfolioThree.vue";
const defaultImage = require("../assets/images/galery.png");
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    AboutThree,
    PortfolioThree,
  },
  data() {
    return {
      show1: false,
      e6: 1,
      show: false,
      formData: {
        admin_nombre: "",
        admin_email: "",
        admin_password: "",
        iglesia_nombre: "",
        iglesia_email: "",
        iglesia_tel: "",
        iglesia_lema: "",
        iglesia_sublema: "",
        iglesia_facebook: "",
        iglesia_logo: defaultImage,
        SLIDE_1_ICON: require("../assets/images/galery.png"),
        SLIDE_1_TITULO: "Titulo",
        SLIDE_1_SUBTITULO: "SubTitulo",
        SLIDE_2_ICON: require("../assets/images/galery.png"),
        SLIDE_2_TITULO: "Titulo",
        SLIDE_2_SUBTITULO: "SubTitulo",
        SLIDE_3_ICON: require("../assets/images/galery.png"),
        SLIDE_3_TITULO: "Titulo",
        SLIDE_3_SUBTITULO: "SubTitulo",
        Peticion_Foto: require("../assets/images/galery.png"),
        Peticion_Titulo: "Titulo",
        Peticion_SubTitulo: "Subtitulo",
        Video: "",
        PrimaryColor: null,
        SecondaryColor: null,
        FooterColor: null,
        Nav_4: "",
        Nav_5: "",
        Nav_6: "",
        Donacion_Foto: require("../assets/images/galery.png"),
        Donacion_Titulo: "",
        Donacion_SubTitulo: "",
        Donaciones: false,
      },
      iglesiaInfo: [
        {
          type: "text",
          rules: "required",
          model: "iglesia_nombre",
          label: "Nombre Iglesia",
        },
        {
          type: "text",
          rules: "required|email",
          model: "iglesia_email",
          label: "Email Iglesia",
        },
        {
          type: "text",
          rules: "required",
          model: "iglesia_lema",
          label: "Lema",
        },
        {
          type: "text",
          rules: "required",
          model: "iglesia_sublema",
          label: "Sub lema",
        },
        {
          type: "text",
          rules: {
            regex: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
          },
          model: "iglesia_tel",
          label: "Telefono",
        },
        {
          type: "text",
          rules: "",
          model: "iglesia_facebook",
          label: "Facebook",
        },
        {
          type: "text",
          rules: "",
          model: "Nav_4",
          label: "Link 1",
        },
        {
          type: "text",
          rules: "",
          model: "Nav_5",
          label: "Link 2",
        },
        {
          type: "text",
          rules: "",
          model: "Nav_6",
          label: "Link 3",
        },
      ],
      slides: [
        
        {
          ICON: "SLIDE_2_ICON",
          TITULO: "SLIDE_2_TITULO",
          SUBTITULO: "SLIDE_2_SUBTITULO",
        },
        {
          ICON: "SLIDE_3_ICON",
          TITULO: "SLIDE_3_TITULO",
          SUBTITULO: "SLIDE_3_SUBTITULO",
        },
      ],
      peticion: [
        {
          ICON: "Peticion_Foto",
          TITULO: "Peticion_Titulo",
          SUBTITULO: "Peticion_SubTitulo",
        },
        {
          ICON: "Foto_Conocenos1",
          TITULO: "Titulo_Conocenos1",
          SUBTITULO: null,
        },
      ],
      donacion: [
        {
          ICON: "Donacion_Foto",
          TITULO: "Donacion_Titulo",
          SUBTITULO: "Donacion_SubTitulo",
        },
      ],
    };
  },
  created() {
    this.getIglesia();
  },
  methods: {
    preview(e, id) {
      console.log(id);
      if (id == "Video") {
        this.video(e);
      }
      if (e.target.files.length > 0) {
        let _this = this;
        var reader = new FileReader();
        reader.onload = function () {
          var output = document.getElementById(id);
          output.src = reader.result;

          _this.formData[id] = output.src;
        };
        reader.readAsDataURL(e.target.files[0]);
      } else {
        return;
      }
    },
    video(e) {
      let file = e.target.files[0];
      let blobURL = URL.createObjectURL(file);

      //document.querySelector("video").src = blobURL;
      this.formData.Video = blobURL;
      console.log(this.formData.Video);
    },
    slideicon(id) {
      console.log(id);
    },
    getIglesia() {
      if (this.$store.state.admin.Admin == 1) {
        console.log(this.$store.state.iglesia);
        this.formData = {
          admin_nombre: this.$store.state.admin.Nombre,
          admin_email: this.$store.state.admin.Email,
          admin_password: this.$store.state.admin.Password,
          iglesia_nombre: this.$store.state.iglesia.Nombre,
          iglesia_email: this.$store.state.iglesia.Email_Contacto,
          iglesia_tel: this.$store.state.iglesia.Telefono,
          iglesia_lema: this.$store.state.iglesia.Lema,
          iglesia_sublema: this.$store.state.iglesia.SubLema,
          iglesia_facebook: this.$store.state.iglesia.Facebook,
          iglesia_logo:
            this.$store.state.iglesia.Logo != null
              ? this.$store.state.iglesia.Logo
              : require("../assets/images/galery.png"),
          SLIDE_1_ICON: this.$store.state.iglesia.SLIDE_1_ICON,
          SLIDE_1_TITULO: this.$store.state.iglesia.SLIDE_1_TITULO,
          SLIDE_1_SUBTITULO: this.$store.state.iglesia.SLIDE_1_SUBTITULO,
          SLIDE_2_ICON: this.$store.state.iglesia.SLIDE_2_ICON,
          SLIDE_2_TITULO: this.$store.state.iglesia.SLIDE_2_TITULO,
          SLIDE_2_SUBTITULO: this.$store.state.iglesia.SLIDE_2_SUBTITULO,
          SLIDE_3_ICON: this.$store.state.iglesia.SLIDE_3_ICON,
          SLIDE_3_TITULO: this.$store.state.iglesia.SLIDE_3_TITULO,
          SLIDE_3_SUBTITULO: this.$store.state.iglesia.SLIDE_3_SUBTITULO,
          Peticion_Foto: this.$store.state.iglesia.Peticion_Foto
            ? this.$store.state.iglesia.Peticion_Foto
            : require("../assets/images/galery.png"),
          Peticion_Titulo: this.$store.state.iglesia.Peticion_Titulo,
          Peticion_SubTitulo: this.$store.state.iglesia.Peticion_SubTitulo,
          Video: this.$store.state.iglesia.Video,
          PrimaryColor: this.$store.state.iglesia.PrimaryColor,

          SecondaryColor: this.$store.state.iglesia.SecondaryColor,
          FooterColor: this.$store.state.iglesia.FooterColor,

          Nav_4: this.$store.state.iglesia.Nav_4,
          Nav_5: this.$store.state.iglesia.Nav_5,
          Nav_6: this.$store.state.iglesia.Nav_6,
          Foto_Conocenos1:
            this.$store.state.iglesia.Foto_Conocenos1 != null
              ? this.$store.state.iglesia.Foto_Conocenos1
              : require("../assets/images/galery.png"),
          Titulo_Conocenos1: this.$store.state.iglesia.Titulo_Conocenos1,
          Donacion_Foto:
            this.$store.state.iglesia.Donacion_Foto != null
              ? this.$store.state.iglesia.Donacion_Foto
              : require("../assets/images/galery.png"),
          Donacion_Titulo: this.$store.state.iglesia.Donacion_Titulo,
          Donacion_SubTitulo: this.$store.state.iglesia.Donacion_SubTitulo,
          Donaciones: this.$store.state.iglesia.Donaciones,
        };
      } else {
        this.$router.replace(`/${this.$route.params.Nombre_Id}`);
      }
    },
    OpenWeb() {
      this.$store.state.loading = true;
      // window.open()
      console.log(window.location.origin);
      setTimeout(() => {
        this.$store
          .dispatch("get_Iglesia", this.$route.params.Nombre_Id)
          .then(() => {
            this.$store.state.loading = false;
            window.open(
              window.location.origin + "/#/" + this.$route.params.Nombre_Id,
              "_top"
            );
          });
      }, 3000);
    },
  },
};
</script>

<style lang="scss">
.w-75 {
  width: 75%;
  @media (max-width: 767.98px) {
    width: 100%;
  }
}

.info-iglesia {
  display: flex;
  align-items: center;
  img {
    width: 350px;
    margin-right: 10px;
  }
  @media (max-width: 767.98px) {
    flex-direction: column;

    img {
      width: 100%;
      margin-bottom: 10px;
    }
    .info-content {
      width: 100%;
    }
  }
}
</style>
